import { website } from '@/axios'
export const unitsLink = 'admin/products/units'

export default {
  getDetails (id) {
    return website().get(`${unitsLink}/${id}`)
  },
  add (payload) {
    return website().post(unitsLink, payload)
  },
  update (id, payload) {
    return website().patch(`${unitsLink}/${id}`, payload)
  },
  getAll () {
    return website().get(`${unitsLink}`)
  }
}
